:root {
  --f-s-sm: 1.35rem;
  --f-s-xs: .75rem;
  --f-s-root-fluid: calc(1rem + .25 * (100vw - 20rem) / 28);
  --f-s-xl-fluid: calc(2.1875rem + 1.5625 * (100vw - 20rem) / 76);
  --f-s-lg-fluid: calc(1.75rem + .5 * (100vw - 20rem) / 76);
  --f-s-md-fluid: calc(1.35rem + .15 * (100vw - 20rem) / 76);
  --f-s-root: clamp(1rem, var(--f-s-root-fluid), 1.25rem);
  --f-s-xl: clamp(2.1875rem, var(--f-s-xl-fluid), 3.75rem);
  --f-s-lg: clamp(1.75rem, var(--f-s-lg-fluid), 2.25rem);
  --f-s-md: clamp(1.35rem, var(--f-s-md-fluid), 1.5rem);
}

@supports not (font-size: clamp(1px, 1vw, 2px)) {
  :root {
    --f-s-root: 1rem;
    --f-s-xl: 2.1875rem;
    --f-s-lg: 1.75rem;
    --f-s-md: 1.35rem;
  }

  @media (min-width: 20rem) and (max-width: 48rem) {
    :root {
      --f-s-root: var(--f-s-root-fluid);
    }
  }

  @media (min-width: 20rem) and (max-width: 120rem) {
    :root {
      --f-s-xl: var(--f-s-xl-fluid);
      --f-s-lg: var(--f-s-lg-fluid);
      --f-s-md: var(--f-s-md-fluid);
    }
  }

  @media (min-width: 48rem) {
    :root {
      --f-s-root: 1.25rem;
    }
  }

  @media (min-width: 120rem) {
    :root {
      --f-s-xl: 3.75rem;
      --f-s-lg: 2.25rem;
      --f-s-md: 1.5rem;
    }
  }
}

:root {
  --c-off-white: #f2f2f4;
  --c-goma-grey: #3c3c3c;
  --c-white: #fff;
  --c-mid-grey: #5f5f5f;
  --c-map-blue: #65677f;
  --c-goma-grey-hover: #5c5c5c;
  --c-goma-grey-selected: #2a2a2c;
  --c-goma-grey-disabled: #d5dadf;
  --c-caption-dark: var(--c-goma-grey);
  --c-caption-light: var(--c-white);
  --c-form-error: #f95252;
  --g-col-size: 6rem;
  --g-col: minmax(min(var(--g-col-size), 100%), 1fr);
  --g-gap: 1.5rem;
  --container-width: 93rem;
  --gutter: calc((100vw - var(--container-width)) / 2);
  --slope: clamp(1rem, min(5vw, 12vh), 5rem);
  --button-bg: var(--c-goma-grey);
  --button-fg: var(--c-white);
  --selection-bg: var(--c-goma-grey);
  --selection-fg: var(--c-white);
}

.jtY6aW_relatedResources.card-grid {
  --column-count: 2;
}

.jtY6aW_tagList {
  z-index: 1;
  flex-wrap: wrap;
  gap: .5em;
  margin: 0;
  list-style-type: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.jtY6aW_tagList:-webkit-any(ul) {
  padding: 0;
}

.jtY6aW_tagList:is(ul) {
  padding: 0;
}

.jtY6aW_tagList li {
  max-width: 100%;
  max-width: min(100%, 21rem);
  font-size: .8rem;
  display: block;
  position: relative;
}

.jtY6aW_tagList li:has(.jtY6aW_activeTag) {
  order: -1;
}

.jtY6aW_tagList li:where(.card *):not(.jtY6aW_activeTag):nth-of-type(n+7) {
  display: none;
}

.jtY6aW_tagList li .jtY6aW_tag {
  --bg: rgba(white, .85);
  width: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

.jtY6aW_tag {
  --_button-bg: var(--button-bg, white);
  --_button-fg: var(--button-fg, var(--c-goma-grey));
  background: var(--_button-bg);
  border-color: var(--_button-bg);
  color: var(--_button-fg);
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid;
  grid-auto-flow: column;
  align-items: center;
  gap: .5em;
  padding: .5em 1em;
  font-size: .8rem;
  text-decoration: none;
  display: inline-grid;
}

.jtY6aW_tag:not(.jtY6aW_activeTag) {
  --button-bg: var(--c-white);
  --button-fg: var(--c-goma-grey);
  --selection-bg: var(--c-white);
  --selection-fg: var(--c-goma-grey);
}

.jtY6aW_tag:not(.jtY6aW_activeTag) svg {
  display: none;
}

.jtY6aW_tag span {
  text-overflow: ellipsis;
  max-width: 20em;
  overflow: hidden;
}

.jtY6aW_tag svg {
  width: .625em;
  margin-right: -.2em;
}

:root {
  --f-s-sm: 1.35rem;
  --f-s-xs: .75rem;
  --f-s-root-fluid: calc(1rem + .25 * (100vw - 20rem) / 28);
  --f-s-xl-fluid: calc(2.1875rem + 1.5625 * (100vw - 20rem) / 76);
  --f-s-lg-fluid: calc(1.75rem + .5 * (100vw - 20rem) / 76);
  --f-s-md-fluid: calc(1.35rem + .15 * (100vw - 20rem) / 76);
  --f-s-root: clamp(1rem, var(--f-s-root-fluid), 1.25rem);
  --f-s-xl: clamp(2.1875rem, var(--f-s-xl-fluid), 3.75rem);
  --f-s-lg: clamp(1.75rem, var(--f-s-lg-fluid), 2.25rem);
  --f-s-md: clamp(1.35rem, var(--f-s-md-fluid), 1.5rem);
}

@supports not (font-size: clamp(1px, 1vw, 2px)) {
  :root {
    --f-s-root: 1rem;
    --f-s-xl: 2.1875rem;
    --f-s-lg: 1.75rem;
    --f-s-md: 1.35rem;
  }

  @media (min-width: 20rem) and (max-width: 48rem) {
    :root {
      --f-s-root: var(--f-s-root-fluid);
    }
  }

  @media (min-width: 20rem) and (max-width: 120rem) {
    :root {
      --f-s-xl: var(--f-s-xl-fluid);
      --f-s-lg: var(--f-s-lg-fluid);
      --f-s-md: var(--f-s-md-fluid);
    }
  }

  @media (min-width: 48rem) {
    :root {
      --f-s-root: 1.25rem;
    }
  }

  @media (min-width: 120rem) {
    :root {
      --f-s-xl: 3.75rem;
      --f-s-lg: 2.25rem;
      --f-s-md: 1.5rem;
    }
  }
}

:root {
  --c-off-white: #f2f2f4;
  --c-goma-grey: #3c3c3c;
  --c-white: #fff;
  --c-mid-grey: #5f5f5f;
  --c-map-blue: #65677f;
  --c-goma-grey-hover: #5c5c5c;
  --c-goma-grey-selected: #2a2a2c;
  --c-goma-grey-disabled: #d5dadf;
  --c-caption-dark: var(--c-goma-grey);
  --c-caption-light: var(--c-white);
  --c-form-error: #f95252;
  --g-col-size: 6rem;
  --g-col: minmax(min(var(--g-col-size), 100%), 1fr);
  --g-gap: 1.5rem;
  --container-width: 93rem;
  --gutter: calc((100vw - var(--container-width)) / 2);
  --slope: clamp(1rem, min(5vw, 12vh), 5rem);
  --button-bg: var(--c-goma-grey);
  --button-fg: var(--c-white);
  --selection-bg: var(--c-goma-grey);
  --selection-fg: var(--c-white);
}

.NTYj3q_filters {
  align-items: center;
  gap: var(--g-gap);
  margin-bottom: calc(var(--g-gap) * -.75);
  display: grid;
}

@container (min-width: 50em) {
  .NTYj3q_filters {
    grid-template-columns: max-content 1fr auto;
  }

  .NTYj3q_filters > :first-child {
    grid-column: 1 / span 2;
  }
}

.NTYj3q_filters p {
  margin: 0;
}

.NTYj3q_filters > * {
  max-width: 100%;
  overflow: auto;
}

.NTYj3q_searchInput form {
  width: 100%;
  max-width: 36em;
  grid-template-columns: [NTYj3q_input-start] 1fr[NTYj3q_loader] max-content[NTYj3q_reset] max-content[NTYj3q_submit] max-content[NTYj3q_input-end];
  grid-auto-flow: row;
  display: grid;
}

.NTYj3q_searchInput form > * {
  grid-row: 1;
}

.NTYj3q_searchInput span {
  grid-column: NTYj3q_loader;
}

.NTYj3q_searchInput button {
  background: none;
  border: none;
  align-self: center;
  padding: .5em;
}

.NTYj3q_searchInput button svg {
  display: block;
}

.NTYj3q_searchInput button[type="reset"] {
  grid-column: NTYj3q_reset;
}

.NTYj3q_searchInput button[type="submit"] {
  grid-column: NTYj3q_submit;
}

.NTYj3q_searchInput input {
  color: inherit;
  border: none;
  border-bottom: 2px solid;
  grid-column: NTYj3q_input-start / NTYj3q_input-end;
  padding-right: 2em;
  font-family: inherit;
  font-size: 2.25em;
}

.NTYj3q_searchInput input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.NTYj3q_searchInput input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

.NTYj3q_searchInput input[type="search"]::-webkit-search-decoration, .NTYj3q_searchInput input[type="search"]::-webkit-search-cancel-button, .NTYj3q_searchInput input[type="search"]::-webkit-search-results-button, .NTYj3q_searchInput input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.NTYj3q_engagementMode {
  grid-auto-flow: column;
  justify-content: start;
  gap: .25em;
  font-size: 1.4em;
  display: grid;
}

.NTYj3q_engagementMode label {
  --_button-bg: var(--button-bg, var(--c-goma-grey));
  --_button-fg: var(--button-fg, white);
  color: inherit;
  background: none;
  border: 2px solid;
  padding: .25em 1em;
  font-weight: 600;
  transition: background .2s, color .2s;
  display: block;
  position: relative;
}

.NTYj3q_engagementMode label input {
  -webkit-appearance: none;
  appearance: none;
  z-index: -1;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.NTYj3q_engagementMode label:not(:has(:disabled)) {
  cursor: pointer;
}

.NTYj3q_engagementMode label:has(:checked) {
  background: var(--_button-bg);
  border-color: var(--_button-bg);
  color: var(--_button-fg);
}

.NTYj3q_engagementMode label:has(:disabled) {
  color: var(--c-goma-grey-disabled);
}

.NTYj3q_pagination {
  text-align: center;
}

/*# sourceMappingURL=resource.module.8970d698.css.map */
