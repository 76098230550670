@use '../../assets/styles/variables';

@mixin module {
  .relatedResources:global(.card-grid) {
    --column-count: 2;
  }

  .tagList {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
    
    @at-root &:is(ul) {
      padding: 0;
    }

    li {
      font-size: 0.8rem;
      display: block;
      position: relative;
      max-width: 100%;
      max-width: min(100%, 21rem);

      &:has(.activeTag) {
        order: -1;
      }

      &:where(:global(.card *)):not(.activeTag):nth-of-type(n+7) {
        display:none;
      }

      .tag {
        --bg: rgba(white, 0.85);

        display: grid;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
    }
  }

  .tag {
    --_button-bg: var(--button-bg, white);
    --_button-fg: var(--button-fg, var(--c-goma-grey));

    background: var(--_button-bg);
    border-color: var(--_button-bg);
    color: var(--_button-fg);

    border: 1px solid;
    display: inline-block;
    font-size: 0.8rem;
    white-space: nowrap;
    padding: 0.5em 1em;
    text-decoration: none;
    text-overflow: ellipsis;

    align-items: center;
    gap: 0.5em;
    grid-auto-flow: column;
    display: inline-grid;

    &:not(.activeTag) {
      @include variables.reversed;

      svg {
        display: none;
      }
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 20em;
    }

    svg {
      margin-right: -0.2em;
      width: .625em;
    }
  }
}

@mixin styles {
  @at-root :where(& > *) {
    margin: 0;
  }

  time {
    font-size: 1.2em;

    &::after {
      content: "";
      border-top: .2em solid;
      display: block;
      left: 0;
      margin: var(--g-gap) auto;
      position: absolute;
      right: 0;
      width: var(--g-gap);
    }
  }

  iframe {
    border:none;

    &[src*="artlines"] {
      aspect-ratio: 1340/768;
      height: 57.3134328358209cqw;
      margin: auto;
      max-height: 75vh;
      max-height: 75cqh;
      max-width: 100%;
      width: auto;

      @container (orientation: portrait) and (max-width: 1200px) {
        aspect-ratio: 548/640;
        height: calc(115cqw - (var(--g-gap) * 2));
      }
    }
  }

  > :not(div, header, footer, section, figure, blockquote, iframe):has(+ :not(div, header, footer, section, figure, blockquote, iframe, :where(h1, h2, h3, h4, h5, h6) + figure)),
  > :where(h1, h2, h3, h4, h5, h6, figure, blockquote, iframe):has(+ figure) {
    margin-bottom: calc(var(--g-gap) * -1);
  }

  @container (min-width: 54em) {
    --column-offset: 2;
    --column-offset-figure: max(1, calc(var(--column-offset) - 1));

    > :not(div, header, footer, section, figure, iframe) {
      grid-column: col-start var(--column-offset) / col-end
        calc(var(--column-offset) * -1);
    }

    > figure {
      grid-column: col-start var(--column-offset-figure) / col-end
        calc(var(--column-offset-figure) * -1);
    }
  }

  @container (min-width: 70em) {
    --column-offset: 3;
  }

  @container (min-width: 85em) {
    --column-offset: 4;
  }

  > header {
    text-align: center;
  }

  --font-size-md: 1.2em;
  --font-size-lg: 1.5em;
  --font-size-xl: 1.75em;

  @at-root :where(& > *) {
    &:where(p:first-of-type) {
      font-size: var(--font-size-md);
    }

    &:where(h1, h2, h3, h4, h5, h6) {
      font-weight: 600;
      line-height: 1.3;
      text-wrap: pretty;
    }

    &:where(h1, h2, h3, h4) {
      font-size: var(--font-size-md);
    }
  }

  @at-root {
    &:not(:has(h2 ~ h3)):has(h1 ~ h2) {
      @at-root {
        :where(& > h1) {
          font-size: var(--font-size-lg);
        }
      }
    }

    &:has(h1 ~ h2 ~ h3) {
      @at-root {
        h1:where(& > *) {
          font-size: var(--font-size-xl);
          font-weight:700;
        }

        h2:where(& > *) {
          font-size: var(--font-size-lg);
        }
      }
    }

    &:has(h1 ~ h2 ~ h3 ~ h4 ~ h5) {
      @at-root {
        h4:where(& > *) {
          font-weight: 500;
        }

        h6:where(& > *) {
          color: #777;
        }
      }
    }
  }

  @at-root :where(& > footer > :not(aside)) {
    grid-column: col-start var(--column-offset, 1) / col-end
        calc(var(--column-offset, 1) * -1);
  }

  figcaption {
    text-align: left;
  }

  blockquote {
    font-family: serif;
    margin: auto;
    text-align: center;
    max-width: 90%
  }

  > figure {
    margin-left: auto;
    margin-right: auto;

    picture::before {
      content: '';
      border: 1px solid rgba(60, 60, 60, 0.1);
      border: 1px solid color-mix(in srgb, var(--c-goma-grey), transparent 90%);
      inset: 0;
      position: absolute;
      pointer-events: none;
    }
  }
}